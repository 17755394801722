import React from 'react'
import Navbartemp from '../components/Navbartemp'
import About from '../components/Aboutbody'
import Footertemp from '../components/Footertemp'

export default function Aboutbody() {
  return (
    <>
    <Navbartemp />
    <About />
    <Footertemp />
    </>
  )
}
