import React from 'react'
import Navbartemp from '../components/Navbartemp'
import SuccessPage from '../components/SuccessPage'
import Footertemp from '../components/Footertemp'

export default function Aboutbody() {
  return (
    <>
    <Navbartemp />
    <SuccessPage />
    <Footertemp />
    </>
  )
}
